<template>
  <div>
    <v-row :class="`pb-5 ${stickyFilter}`">
      <v-col cols="12" sm="4" lg="3">
        <BaseDatePicker
          :monthPicker="type === 'monthly' ? true : false"
          v-model="to"
          :label="
            type === 'monthly'
              ? 'summary.reference-month'
              : 'summary.reference-date'
          "
          @input="getSummary"
      /></v-col>
      <v-col cols="12" sm="8" lg="6">
        <v-slider
          v-model="milestone"
          :label="slider.label"
          :tick-labels="slider.tickLabels"
          :max="3"
          ticks="always"
          :tick-size="4"
          hide-details
          @change="getSummary"
        ></v-slider
      ></v-col>
    </v-row>
    <v-data-table
      fixed-header
      hide-default-footer
      disable-pagination
      :headers="headers"
      :items="entries"
      :items-per-page="10"
      :item-class="rowClass"
      class="elevation-1 data-table"
      :height="$vuetify.breakpoint.smAndUp ? 'calc(100vh - 310px)' : ''"
      :loading="tableLoading"
      :loading-text="$t('summary.loading')"
      @click:row="item => selectEntry(item.date)"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        <div
          :class="
            header.value === 'date' ? 'font-weight-bold primary--text' : ''
          "
          :key="header.value"
        >
          {{ header.formatter(value) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import InvoiceService from '@/services/modules/InvoiceService'
import { displayErrorMessage } from '@/utils'
import { mapState } from 'vuex'
import { formatDate } from '@/utils'

export default {
  name: 'SummaryTable',

  data() {
    return {
      entries: [],
      to: null,
      milestone: 0,
      tableLoading: false,
      headers: null,
      stickyFilter: this.$vuetify.breakpoint.xsOnly ? 'sticky-el--submenu' : ''
    }
  },

  props: { type: String },

  computed: {
    ...mapState('common', ['locale']),

    slider() {
      if (this.type === 'daily') {
        return {
          label: this.$t('summary.days-before'),
          tickLabels: ['7', '14', '30', '60'],
          value: [7, 14, 30, 60]
        }
      } else if (this.type === 'weekly') {
        return {
          label: this.$t('summary.weeks-before'),
          tickLabels: ['4', '12', '24', '52'],
          value: [4, 12, 24, 52]
        }
      } else {
        return {
          label: this.$t('summary.months-before'),
          tickLabels: ['3', '6', '9', '12'],
          value: [3, 6, 9, 12]
        }
      }
    }
  },

  mounted() {
    this.getSummary()
    this.setHeaders(this.type)
  },

  watch: {
    type: {
      immediate: true,
      handler: function() {
        this.setHeaders()
      }
    },

    locale() {
      this.setHeaders()
    }
  },

  methods: {
    setHeaders() {
      let dateHeader
      if (this.type === 'daily') {
        dateHeader = 'summary.daily-header'
      } else if (this.type === 'weekly') {
        dateHeader = 'summary.weekly-header'
      } else {
        dateHeader = 'summary.monthly-header'
      }
      this.headers = [
        {
          text: this.$t(dateHeader),
          sortable: false,
          value: 'date',
          formatter: this.customizeDate
        },
        {
          text: this.$t('summary.total-amount'),
          sortable: false,
          value: 'totalAmount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.total-received'),
          sortable: false,
          value: 'totalReceived',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.cash-amount'),
          sortable: false,
          value: 'cashAmount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.wire-amount'),
          sortable: false,
          value: 'wireAmount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.card-amount'),
          sortable: false,
          value: 'cardAmount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.order-count'),
          sortable: false,
          value: 'orderCount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.invoice-count'),
          sortable: false,
          value: 'invoiceCount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.pending-count'),
          sortable: false,
          value: 'pendingCount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.paid-count'),
          sortable: false,
          value: 'paidCount',
          formatter: this.formatNumber
        },
        {
          text: this.$t('summary.cancelled-count'),
          sortable: false,
          value: 'cancelledCount',
          formatter: this.formatNumber
        }
      ]
    },

    getSummary() {
      this.tableLoading = true
      InvoiceService.getSummary(
        this.type,
        this.to,
        this.slider.value[this.milestone]
      )
        .then(res => {
          this.entries = res.data
          this.tableLoading = false
        })
        .catch(err => displayErrorMessage(err))
    },

    customizeDate(dateString) {
      const date = new Date(dateString)
      if (this.type === 'daily') {
        return formatDate(date, 'E, dd/MM')
      } else if (this.type === 'weekly') {
        return formatDate(date, 'dd/MM')
      } else {
        return formatDate(date, 'MM/yyyy')
      }
    },

    formatNumber(value) {
      return value.toLocaleString()
    },

    rowClass() {
      return 'clickable'
    },

    selectEntry(value) {
      this.$store.commit('common/SET_AUTO_RANGE', {
        type: this.type,
        date: value
      })
      this.$router.push({ name: 'orders-invoices' })
    }
  }
}
</script>

<style scoped></style>
